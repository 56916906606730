import { Flex, Link, Box, Container } from '@chakra-ui/react'
import Logo from '@/components/Logo'
import { useStickyHeader } from 'react-use-sticky-header'
import useIntersection from '@/hooks/useIntersection'
import * as amplitude from '@amplitude/analytics-browser'
import { USER_GUIDE_LINK, PRICING_LINK } from '@/configs'

import SignIn from '@/components/common/SignIn'

const Header = () => {
  const [setHeaderRef]: HTMLElement = useStickyHeader()
  const isBannerSection = useIntersection('#landing-banner', '-120px')

  return (
    <Box
      width="100%"
      zIndex="10"
      py={{ base: '12px' }}
      bg={isBannerSection ? 'transparent' : 'rgba(232, 232, 232, 0.4)'}
      backdropFilter={isBannerSection ? '' : 'blur(9px)'}
      transition="all 0.1s ease-out"
      ref={setHeaderRef}
      className="header"
    >
      <Container maxW="1280px">
        <Flex align={'center'} bg="transparent">
          <Link href={'/'} _hover={{ textDecoration: 'none' }} display="inline-block">
            <Logo isWhite={isBannerSection} />
          </Link>
          <Box ml="auto">
            <Link
              href={PRICING_LINK}
              fontWeight="500"
              fontSize={{ base: '14px', md: '16px', lg: '18px' }}
              lineHeight="100%"
              color="#fff"
              variant="link"
              target="_blank"
              mr="24px"
              display="inline"
              onClick={() => amplitude.track('pricing_clicked')}
            >
              Pricing
            </Link>
            <Link
              href={USER_GUIDE_LINK}
              fontWeight="500"
              fontSize={{ base: '14px', md: '16px', lg: '18px' }}
              lineHeight="100%"
              color="#fff"
              variant="link"
              target="_blank"
              mr="24px"
              display="inline"
              onClick={() => amplitude.track('user_guide_clicked')}
            >
              User Guide
            </Link>
            <SignIn
              borderRadius="4px"
              fontSize={{ base: '14px', md: '16px', lg: '18px' }}
              lineHeight="20px"
              fontWeight="500"
              className="btn-hover btn-active"
              color="#000"
              bg="#fff"
              _hover={{ bg: '#fff' }}
              _active={{ bg: '#eee' }}
              minW={{ base: '88px', lg: '180px' }}
              h={{ base: '32px', lg: '48px' }}
              text={'Sign In'}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Header
