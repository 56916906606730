import { ethers } from 'ethers'
import { useState } from 'react'
import marriage from '@/configs/ABIs/WavePortal7.json'
import marriageSepolia from '@/configs/ABIs/WavePortal7Sepolia.json'
import { createProvider } from '@/eth/provider'
import * as amplitude from '@amplitude/analytics-browser'
import { CONTRACTS } from '@/configs/contracts'

import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '@/store'
import { setMarriageContract, setMarriageContractEvents, loadParams } from '@/store/features/mainContract/reducer'
import {
  setProvider,
  setProviderEvents,
  setSignerEvents,
  setSigner,
  setWrongNetwork,
} from '@/store/features/wallet/reducer'

const useContract = () => {
  const { currentAccount, currentNetwork, supportedNetworks, newProvider } = useSelector(
    (state: IRootState) => state.wallet,
  )
  const [isLoaded, setIsLoaded] = useState(false)
  const dispatch = useDispatch()

  const identifyObj = new amplitude.Identify()
  amplitude.identify(identifyObj)

  const loadProvider = () => {
    try {
      const provider = new ethers.providers.Web3Provider(newProvider, 'any')
      const providerEvents = createProvider(currentNetwork)
      dispatch(setProvider(provider))
      if (currentNetwork === '0x89') {
        dispatch(setProviderEvents(providerEvents))
      } else {
        dispatch(setProviderEvents(provider))
      }
      loadSigner(provider, providerEvents)
    } catch (error) {
      throw new Error(error)
    }
  }

  const loadWallet = () => {
    try {
      if (!supportedNetworks.includes(currentNetwork)) {
        amplitude.track('wrong_network_connected')
        dispatch(setWrongNetwork(true))
      } else if (supportedNetworks.includes(currentNetwork)) {
        dispatch(loadParams(currentNetwork))
      }
      amplitude.setUserId(currentAccount)
      identifyObj.setOnce('wallet_address', currentAccount)
      identifyObj.setOnce('first_connected_chains', currentNetwork)
      amplitude.identify(identifyObj)
      const eventProperties = {
        connected_network: currentNetwork,
      }
      amplitude.track('wallet_connected', eventProperties)
    } catch (error) {
      throw new Error(error)
    }
  }

  const loadSigner = (provider: any, providerEvents: any) => {
    try {
      const signer = provider.getUncheckedSigner()
      dispatch(setSigner(signer))
      const signerEvents = providerEvents.getSigner(currentAccount)
      if (currentNetwork === '0x89') {
        setSignerEvents(signerEvents)
      } else {
        setSignerEvents(signer)
      }
      loadMarriageContract(provider, signerEvents)
      return signer
    } catch (error) {
      throw new Error(error)
    }
  }

  const loadMarriageContract = (provider: any, signerEvents: any) => {
    try {
      let MarriageContractABI
      const signer = provider.getUncheckedSigner()
      marriageSepolia
      if (currentNetwork === '0xaa36a7' || currentNetwork === '0x1') {
        MarriageContractABI = marriageSepolia.abi
      } else {
        MarriageContractABI = marriage.abi
      }
      const marrContract = new ethers.Contract(CONTRACTS[currentNetwork].ContractAddress, MarriageContractABI, signer)
      identifyObj.setOnce('main_contract_address', CONTRACTS[currentNetwork].ContractAddress)
      amplitude.identify(identifyObj)
      dispatch(setMarriageContract(marrContract))
      const marriageContractEvents = new ethers.Contract(
        CONTRACTS[currentNetwork].ContractAddress,
        marriage.abi,
        signerEvents,
      )
      dispatch(setMarriageContractEvents(marriageContractEvents))
      setIsLoaded(true)
      return marrContract
    } catch (error) {
      throw new Error(error)
    }
  }

  const loadContract = () => {
    setIsLoaded(false)
    loadWallet()
    loadProvider()
  }

  return {
    loadContract,
    isLoaded,
  }
}

export default useContract
